#map-container {
    position: relative;
    width: 100%;
    height: 70vh; /* Adjust height as needed */
}

.mapbox {
    position: relative;
    height: 70vh;
    width: 100%;
    transition: all 0.3s ease;
    border: 1px solid #e1e8ea;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5); /* Adds a shadow */
    margin-top: 5px;
}

.style-dropdown {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;  /* Ensure it appears above the map */
    padding: 10px 15px;
    /* background-color: #007ac2;  Customize color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
}

/* Optional: Styling for the dropdown */
.dropdown {
    height: 3lvh;
    width: 15lvw;
}

/*.esri-popup__main-container { 
    display: none;
}*/