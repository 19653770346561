/* src/LeftSidebar.css */
.sidebar-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.sidebar-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms, transform 500ms;
}

.sidebar-exit {
    opacity: 1;
    transform: translateX(0);
}

.sidebar-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 100ms, transform 300ms;
}

.sidebar-enter-done {
    opacity: 1;
    transform: translateX(0);
}

.sidebar-exit-done {
    opacity: 0;
    transform: translateX(-100%);
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.overlay-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 60%;
    max-height: 70%;
    position: relative;
    overflow-y: auto;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.grid-images {

    justifyContent: center;
    alignItems: center;
    minHeight: 400px;
}

.container-image {
    display: flex;
    justify-content: center;
    align-items: center;
}

.church-info-side {
    padding-left: 10px;
    padding-right: 10px;
}

.church-info-side li {
    padding: 8px 0;         /* Add padding inside each list item */
    margin-bottom: 8px;     /* Add margin to create space between list items */
    border-bottom: 1px solid #ddd; /* Optional: Add a border between items for separation */
    box-sizing: border-box; /* Include padding and border in the total width/height */
}

.church-info-side ul {
    margin: 0;              /* Remove default margin from the ul */
    padding: 0;             /* Remove default padding from the ul */
    list-style-type: none;  /* Remove default bullet points */
}

.church-info-side img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Ensure the image covers the div without distortion */
}