.popup {
    position: fixed;
    top: 50%;
    left: 60%;
    transform: translate(-50%, -50%);
    padding: 20px;
    background-color: white;
    border: 3px dotted #ccc;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    z-index: 1000;
    overflow-y: auto;
    max-height: 50vh;
    width: 300px; /* Adjust width as needed */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #333;
}

.popup-close:hover {
    color: red; /* Optional: change color on hover */
}
