html, body {
    height: 100%; 
    margin: 0; 
    overflow-y: auto; 
}

.team {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.team img {
    width: 100%; 
    height: 100%; 
}

.team p {
    margin: 10px 0 0 0; 
    font-size: large;
    margin-bottom: 10px;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /*2 equal width columns
    gap: 10px; /* Adds space between the grid items */
    padding: 20px;
}

.grid-item {
    background-color: white; /* Green background */
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    border-radius: 5px;
    /* border: 1px solid;
    border-color: black; */
    height: 50vh; 
    margin: 0 5px;
}

.grid-item img {
    width: 100%; 
    height: 100%; 
    object-fit: cover; 
}

.grid-contact {
    background-color: white; /* Green background */
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    border-radius: 5px;
    /* border: 1px solid;
    border-color: black; */
    /* height: 50vh;  */
    margin: 0 5px;
}

.grid-contact p {
    margin: 10px 0 0 0; 
}

.email-link {
    color: black; 
    text-decoration: underline; 
    cursor: pointer; 
    margin: 10px 0 0 0;
}

.email-link:hover {
    color: darkgreen; /* Change text color on hover */
}
